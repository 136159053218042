
import { computed, defineComponent, provide, ref } from 'vue'
import { api } from '@/api/useAxios';
import { Toast,Dialog } from 'vant';
import { useRouter } from 'vue-router';
import {Homework} from "momai";
import { getUserId } from '@/utils/auth';

export default defineComponent({
  name: 'Comment',
  props: {
    ordId:{ //ordId
      type: Number,
      required: true
    },
    ordType: {  //类型，1：作业，2：评论,3：活动,4:打卡
      type: Number,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    createTime: {  //创建时间
      type: String,
      default: '1小时前'
    },
    dzCnt: {  //点赞数
      type: Number,
      default: 0
    },
    pjCnt: {  //评价数
      type: Number,
      default: 0
    },
    scCnt: {  //收藏数
      type: Number,
      default: 0
    },
    fxCnt: {  //分享数
      type: Number,
      default: 0
    },
    hasDz: {  //我是否点赞
      type: Boolean,
      default: false
    },
    hasSc: {  //我是否收藏
      type: Boolean,
      default: false
    },
    showSelf: {  //是否展示公开属性
      type: Boolean,
      default: false
    },
    isSelf: {  //是否公开
      type: Boolean
    },
    teaEva: {  //导师评价
      type: String,
      default:''
    },
    teaPic: { //导师图片评价
      type: Array,
      default: []
    },
    teaVoice: {  //导师语音评价
      type: String,
      default:''
    },
    isQuality: { //是否优质
      type: Boolean,
      default: false
    },
    workStatus: {
      type: String,
      default:''
    },
    showShare: { //展示分享按钮
      type: Boolean,
      default: true
    },
    userId: {
      type: Number,
      required: true
    },
    showVideo: {
      type: Boolean,
      default: false
    },
    showAudio: {
      type: Boolean,
      default: false
    },
    showImg: {
      type: Boolean,
      default: false
    },
    tipTag: {
      type: Boolean,
      default: false
    },

    teacherName: {
      type: String,
      default: false
    },
    teacherAvatar: {
      type: String,
      default: false
    },
    teacherId: {
      type: String,
      default: false
    }
  },
  setup: (props) => {
    const showDelete =  computed(() => {
      if(props.ordType === 1  && props.workStatus === '0' && props.userId === Number(getUserId()) ){
        return true
      }else{
        return false
      }
    })
    const commentTag = ref(false)
    const router = useRouter()
    const isDz = ref(props.hasDz)
    const dzNum = ref(props.dzCnt)
    const scNum = ref(props.scCnt)
    const isSc = ref(props.hasSc)
    // 点赞
    const handleDZ = () => {
      const dzParams = {
        "dealType": isDz.value? 'cancel': 'add',
        "ord_id": props.ordId,
        "type": props.ordType
      }
      api.post('/mmdianzan/addOrCancelDz', dzParams).then(resp => {
        const dzFlag = dzParams.dealType==='cancel'
        isDz.value = dzFlag? false: true
        dzNum.value += dzFlag? -1: 1
      })
    }
    // 收藏
    const handleSC = () => {
      const scParams = {
        "dealType": isSc.value? 'cancel': 'add',
        "ord_id": props.ordId,
        "type": props.ordType
      }
      api.post('/mmshoucang/addOrCancelSc', scParams).then(resp => {
        const scFlag = scParams.dealType==='cancel'
        isSc.value = scFlag? false: true
        scNum.value += scFlag? -1: 1
      })
    }

    const handleShare = () => {
      if (props.ordType === 1) {
        router.push('/homework/detail/' + props.ordId + '?showTag=true')
      } else if (props.ordType === 4) {
        router.push('/daily/share/' + props.ordId)
      }
    }


    const showPopover = ref(false);
    const actions = [
      { text: '删除',type: 1 }
      ];
    const askDel = () => {
      Dialog.confirm({
        message: '确认删除吗？',
        title: '确认'
      }).then(() => {
        api.post('/homework/delete', [props.ordId]).then(() => {
          Toast.success('删除成功,请刷新当前页面！')
        })
      }).catch(() => {})
    }
     const onSelect = (action:any) => {
        switch (action.type) {
          case 1:
            askDel()
            break
        }

     }

    const comment = () => {
      commentTag.value = !commentTag.value
    }
    // 评论
    provide('commentTag', commentTag)

    // 处理评论提交
    const commentSub = (params: any) => {
      api.post('/mmpinglun/save', params).then( () => {
        Toast.success('提交成功')
      })
    }

    const toPage = (teacherId: number) => {
      router.push('/teacher/detail/' + teacherId)
    }

    return {
      handleDZ,
      handleSC,
      isDz,
      dzNum,
      isSc,
      scNum,
      showPopover,
      actions,
      onSelect,
      commentTag,
      comment,
      commentSub,
      handleShare,
      askDel,
      showDelete,
      toPage
    }
  }

})
